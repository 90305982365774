<template>
  <div class="admin-users-billing">
    <div class="toolbar justify-content-between">
      <div>
        <i class="icon-button" :class="$$config.icons.general.back" v-b-tooltip title="Back to Admin" @click="clickBack"></i>
      </div>
      <div>
      </div>
    </div>
    <div class="list-containter">
      <b-card>
        <b-card-header><h2>User Billing Report</h2></b-card-header>
          <b-card-body>
            <table class="user-billing-table">
              <template v-for="user of userList">
                <thead :key="user.userId+'header'">
                <tr>
                  <td style="min-width: 5rem">UserID</td>
                  <td style="min-width: 12rem">Name</td>
                  <td style="min-width: 10rem">Phone</td>
                  <td style="min-width: 12rem">Email</td>
                </tr>
                </thead>
                <tr :key="user.userId">
                  <td>{{user.userId}}</td>
                  <td>{{user.name}}</td>
                  <td>{{user.mobileNumber}}</td>
                  <td>{{user.email}}</td>
                </tr>
                <tr :key="user.userId+'deviceHeader'" class="user-billing-table-subheading">
                  <td colspan="2">Device ID</td>
                  <td>Subscribed</td>
                  <td>Sub Expiry</td>
                </tr>
                <tr v-for="device of getUserDevices(user)" :key="device.id">
                  <td colspan="2">{{device.device_id}}</td>
                  <td>{{device.has_subscription}}</td>
                  <td>{{device.subscription_expiry}}</td>
                </tr>
                <tr :key="user.userId+'total'" class="user-billing-table-subheading">
                  <td colspan="3">Total Devices:</td>
                  <td>{{user.devices.length}}</td>
                </tr>
              </template>
            </table>
          </b-card-body>
      </b-card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'admin-users-billing',
  components: {
  },
  data: function () {
    return {
      devicesByIMEI: {},
      userList: [
        {
          userId: '1',
          devices: ['351608083567144', '351608083567145'],
          notifications: [],
          email: 'bob@hotmail.com',
          mobileNumber: '0417554554',
          name: 'Bob Smith',
          activated: false,
          admin: false,
          lastLogin: '2020-01-01 09:90'
        },
        {
          userId: '2',
          devices: ['351608083567146'],
          notifications: [],
          email: 'bob@hotmail.com',
          mobileNumber: '0417554554',
          name: 'Bob Smith2',
          activated: false,
          admin: false,
          lastLogin: '2020-01-01 09:90'
        },
        {
          userId: '3',
          devices: ['351608083567144', '351608083567145'],
          notifications: [],
          email: 'bob@hotmail.com',
          mobileNumber: '0417554554',
          name: 'Bob Smith3',
          activated: false,
          admin: false,
          lastLogin: '2020-01-01 09:90'
        }
      ],
      deviceList: [
        {
          device_id: 'A3039494',
          device_password: 'password',
          device_phone: '042331344',
          alarm_status: true,
          alarm_changed_time_utc: 32142141414,
          overspeed_limit: 0,
          vibration_alarm_active: false,
          odometer_reading: 0,
          has_subscription: true,
          icon: 'Car',
          imei: '351608083567144',
          name: 'Matty\'s car ',
          plant_num: 'matty D',
          registration: '',
          subscription_expiry: '2017-06-01',
          user_id: 1,
          livetrack: false,
          geolimit: false,
          voltage: 12.0,
          hours_tracked: '200'
        },
        {
          device_id: 'A3836444',
          device_password: 'password',
          device_phone: '042331344',
          alarm_status: false,
          alarm_changed_time_utc: 32142141414,
          overspeed_limit: 0,
          vibration_alarm_active: false,
          odometer_reading: 0,
          has_subscription: true,
          icon: 'Car',
          imei: '351608083567145',
          name: 'Matty\'s car ',
          plant_num: 'matty D',
          registration: '',
          subscription_expiry: '2017-06-01',
          user_id: 1,
          livetrack: false,
          geolimit: false,
          voltage: 12.0,
          hours_tracked: '200'
        },
        {
          device_id: 'A4748284',
          device_password: 'password',
          device_phone: '042331344',
          alarm_status: false,
          alarm_changed_time_utc: 32142141414,
          overspeed_limit: 0,
          vibration_alarm_active: false,
          odometer_reading: 0,
          has_subscription: true,
          icon: 'Car',
          imei: '351608083567146',
          name: 'Matty\'s car ',
          plant_num: 'matty D',
          registration: '',
          subscription_expiry: '2017-06-01',
          user_id: 1,
          livetrack: false,
          geolimit: false,
          voltage: 12.0,
          hours_tracked: '200'
        },
        {
          device_id: 1,
          device_password: 'password',
          device_phone: '042331344',
          alarm_status: false,
          alarm_changed_time_utc: 32142141414,
          overspeed_limit: 0,
          vibration_alarm_active: false,
          odometer_reading: 0,
          has_subscription: true,
          icon: 'Car',
          imei: '351608083567144',
          name: 'Matty\'s car ',
          plant_num: 'matty D',
          registration: '',
          subscription_expiry: '2017-06-01',
          user_id: 1,
          livetrack: false,
          geolimit: false,
          voltage: 12.0,
          hours_tracked: '200'
        }
      ]
    }
  },
  created () {
  },
  methods: {
    getUserDevices: function (user) {
      // TODO - Double-check this works with REAL data.
      return this.deviceList.filter(device => user.devices.includes(device.device_imei))
    },
    clickBack: function () {
      this.$router.push({ path: '/admin' })
    },
    selectChange: function (newSelection) {
      this.selectedUsers = newSelection
    },
    createUser: function () {
      this.$bvModal.show('modal-create-user')
    },
    refreshList: function () {
      this.$refs.adminUserList.loadData()
    }
  }
}
</script>

<style scoped lang="scss">
  @import '../../variables';

  .admin-users-billing {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 2%;
    overflow-y: auto;
  }

  .list-containter {
    background: $theme-color-background-4;
    border-radius: 5px;
    padding: 5px;
    margin: 5px;
  }

  .toolbar {
    width: 100%;
    display: flex;
    flex-direction: row;
    font-size: 3rem;
  }

  .user-billing-table {
    border: 1px solid white;
  }

  .user-billing-table-subheading td{
    font-weight: 700;
    background: $theme-color-background-1!important;
    color: $theme-color-primary-6;
  }

  .user-billing-table thead td{
    font-weight: 700;
    background: $theme-color-background-1;
    border: 1px solid white;
  }

  .user-billing-table td{
    background: $theme-color-background-2;
    border: 1px solid white;
  }

</style>
