var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "admin-users-billing" }, [
    _c("div", { staticClass: "toolbar justify-content-between" }, [
      _c("div", [
        _c("i", {
          directives: [{ name: "b-tooltip", rawName: "v-b-tooltip" }],
          staticClass: "icon-button",
          class: _vm.$$config.icons.general.back,
          attrs: { title: "Back to Admin" },
          on: { click: _vm.clickBack }
        })
      ]),
      _c("div")
    ]),
    _c(
      "div",
      { staticClass: "list-containter" },
      [
        _c(
          "b-card",
          [
            _c("b-card-header", [_c("h2", [_vm._v("User Billing Report")])]),
            _c("b-card-body", [
              _c(
                "table",
                { staticClass: "user-billing-table" },
                [
                  _vm._l(_vm.userList, function(user) {
                    return [
                      _c("thead", { key: user.userId + "header" }, [
                        _c("tr", [
                          _c("td", { staticStyle: { "min-width": "5rem" } }, [
                            _vm._v("UserID")
                          ]),
                          _c("td", { staticStyle: { "min-width": "12rem" } }, [
                            _vm._v("Name")
                          ]),
                          _c("td", { staticStyle: { "min-width": "10rem" } }, [
                            _vm._v("Phone")
                          ]),
                          _c("td", { staticStyle: { "min-width": "12rem" } }, [
                            _vm._v("Email")
                          ])
                        ])
                      ]),
                      _c("tr", { key: user.userId }, [
                        _c("td", [_vm._v(_vm._s(user.userId))]),
                        _c("td", [_vm._v(_vm._s(user.name))]),
                        _c("td", [_vm._v(_vm._s(user.mobileNumber))]),
                        _c("td", [_vm._v(_vm._s(user.email))])
                      ]),
                      _c(
                        "tr",
                        {
                          key: user.userId + "deviceHeader",
                          staticClass: "user-billing-table-subheading"
                        },
                        [
                          _c("td", { attrs: { colspan: "2" } }, [
                            _vm._v("Device ID")
                          ]),
                          _c("td", [_vm._v("Subscribed")]),
                          _c("td", [_vm._v("Sub Expiry")])
                        ]
                      ),
                      _vm._l(_vm.getUserDevices(user), function(device) {
                        return _c("tr", { key: device.id }, [
                          _c("td", { attrs: { colspan: "2" } }, [
                            _vm._v(_vm._s(device.device_id))
                          ]),
                          _c("td", [_vm._v(_vm._s(device.has_subscription))]),
                          _c("td", [_vm._v(_vm._s(device.subscription_expiry))])
                        ])
                      }),
                      _c(
                        "tr",
                        {
                          key: user.userId + "total",
                          staticClass: "user-billing-table-subheading"
                        },
                        [
                          _c("td", { attrs: { colspan: "3" } }, [
                            _vm._v("Total Devices:")
                          ]),
                          _c("td", [_vm._v(_vm._s(user.devices.length))])
                        ]
                      )
                    ]
                  })
                ],
                2
              )
            ])
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }